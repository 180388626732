<template>
	<div id="availability" class="row row-fluid row-has-fill">
		<div class="container">
			<div class="col-sm-12">
				<div class="column-inner">
					<div class="wrapper">
						<div class="spacer-sm"></div>
						<div class="title">
							<h2 class="head-fancy mt-0"><span>Availability</span></h2>
						</div>
						<div class="spacer"></div>
						<section class="contact-us">
							<div class="row">
								<div class="col-md-8 col-sm-12 col-sx-12" style="max-width: 740px;">
									<div v-if="!loading" class="row padding-r-40 padding-l-40">
										<table class="calendar">
											<tr>
												<td class="fix-width-50">
													<button @click="prevGo()" class="cal-btn trans-500ms"><b-icon icon="chevron-left"></b-icon></button>
												</td>
												<th v-if="desktopView" colspan="6">{{
                                                        this.selectedView.monthOne.fullName
                                                    }}
                                                    {{ this.selectedView.year }}</th>
												<th v-if="!desktopView" colspan="4">{{
                                                        this.selectedView.monthOne.fullName
                                                    }}
                                                    {{ this.selectedView.year }}</th>
												<td v-if="!desktopView" class="fix-width-50">
													<button @click="nextGo()" class="cal-btn trans-500ms"><b-icon icon="chevron-right"></b-icon></button>
												</td>
											</tr>
											<tr id="row-days" style="border-bottom: 2px solid #cecece;">
												<td class="last-child-row fix-width-50" v-for="d in days" v-bind:key="d.num">{{d.shortName}}</td>
											</tr>
											<tr v-for="(week, index) in dateList[selectedView.monthOne.num - 1].weeks" v-bind:key="index" style="height: 50px;">
												<td v-bind:class="[day.class, 'last-child-row', 'fix-width-50']" v-for="(day, index) in week" v-bind:key="index" @click="clickEvents(day, selectedView.monthOne)" style="cursor: pointer;">
													<b>{{day.value}}</b>
												</td>
											</tr>
										</table>
										<table v-if="desktopView" class="calendar">
											<tr>
												<th colspan="6">{{ this.selectedView.monthTwo.fullName }}
                                                    {{ this.selectedView.year }}</th>
												<td class="fix-width-50">
													<button @click="nextGo()" class="cal-btn trans-500ms"><b-icon icon="chevron-right"></b-icon></button>
												</td>
											</tr>
											<tr id="row-dayss" style="border-bottom: 2px solid #cecece;">
												<td class="last-child-row fix-width-50" v-for="d in days" v-bind:key="d.num">{{d.shortName}}</td>
											</tr>
											<tr v-for="(week, index) in dateList[selectedView.monthTwo.num - 1].weeks" v-bind:key="index" style="height: 50px;">
												<td v-bind:class="[day.class, 'last-child-row', 'fix-width-50']" v-for="(day, index) in week" v-bind:key="index" @click="clickEvents(day, selectedView.monthTwo)" style="cursor: pointer;">
													<b>{{day.value}}</b>
												</td>
											</tr>
										</table>
										<p>*Please specify booking dates in <i>Details</i></p>
									</div>
                                    <div v-else><p>Loading...</p></div>
								</div>
								<div v-if="!desktopView" class="spacer-xs"></div>
								<div class="col-md-4 col-sm12 col-xs-12 padding-l-40">
									<div v-if="querySent" class="mgs-response"></div>
									<form>
										<h4 class="head-sub">Send Us  <span>an Inquiry</span></h4>
										<p>
											<span class="divider-line"></span>
										</p>
										<div class="form">
											<label>
												<input v-model="form.name" type="text" value-size="40" placeholder="Full Name*">
											</label>
											<br>
											<label>
												<input v-model="form.email" type="text" value-size="40" placeholder="Email Address*">
											</label>
											<br>
											<label>
												<input v-model="form.subject" type="text" value-size="40" placeholder="Subject*">
											</label>
											<label>
												<select class="block select" v-model="form.personNum">
													<option value="" disabled selected>No of Persons*</option>
													<option value="1">1 Person</option>
													<option value="2">2 Person</option>
													<option value="3">3 Person</option>
													<option value="4">4 Person</option>
													<option value="5">5 Person</option>
													<option value="6">6 Person</option>
													<option value="7">7 Person</option>
													<option value="8">8 Person</option>
													<option value="9">9 Person</option>
													<option value="10">10 Person</option>
												</select>
											</label>
											<br>
											<label>
												<textarea v-model="form.message" cols="40" rows="10" placeholder="Details*"></textarea>
											</label>
											<br>
										</div>
									</form>
									<div  style="text-align: left;">
										<button @click="sendInquiry()" class="btn-side">
											Send
											<span>
												<b-icon icon="plus" font-scale="1" style="display: inline-block; font-size: inherit; text-rendering: auto; -webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;"></b-icon>
											</span>
										</button>
									</div>
								</div>
							</div>
						</section>
						<div class="title">
							<h2 class="head-fancy mt-0">Price<span>List</span></h2>
						</div>
						<div class="spacer-sm"></div>
						<table class="price-list">
							<tr>
								<th>Period(from-to)</th>
								<th>Price in EUR(night)</th>
								<th>Minimum Stay</th>
								<th>Arrival Day</th>
							</tr>
							<tr>
                                <td>02.11.2024 - 21.12.2024</td>
                                <td>300,00 €</td>
                                <td>5</td>
                                <td>Any</td>
                            </tr>
							<tr>
								<td>21.12.2024 - 07.01.2025</td>
								<td>800,00 €</td>
								<td>3</td>
								<td>Any</td>
							</tr>
                            <tr>
                                <td>01.03.2025 - 29.03.2025</td>
                                <td>300,00 €</td>
                                <td>5</td>
                                <td>Any</td>
                            </tr>
							<tr>
                                <td>29.03.2025 - 10.05.2025</td>
                                <td>400,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
                                <td>10.05.2025 - 17.05.2025</td>
                                <td>450,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
								<td>17.05.2025 - 24.05.2025</td>
								<td>475,00 €</td>
								<td>7</td>
								<td>Any</td>
							</tr>
                            <tr>
                                <td>24.05.2025 - 31.05.2025</td>
                                <td>550,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
                                <td>31.05.2025 - 14.06.2025</td>
                                <td>600,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
                                <td>14.06.2025 - 28.06.2025</td>
                                <td>630,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
								<td>28.06.2025 - 05.07.2025</td>
								<td>650,00 €</td>
								<td>7</td>
								<td>Any</td>
							</tr>
							<tr>
								<td>05.07.2025 - 12.07.2025</td>
								<td>700,00 €</td>
								<td>7</td>
								<td>Any</td>
							</tr>
                            <tr>
                                <td>12.07.2025 - 19.07.2025</td>
                                <td>750,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
                                <td>19.07.2025 - 26.07.2025</td>
                                <td>850,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
                                <td>26.07.2025 - 16.08.2025</td>
                                <td>925,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
								<td>16.08.2025 - 23.08.2025</td>
								<td>850,00 €</td>
								<td>7</td>
								<td>Any</td>
							</tr>
                            <tr>
                                <td>23.08.2025 - 30.08.2025</td>
                                <td>775,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
                                <td>30.08.2025 - 06.09.2025</td>
                                <td>700,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
								<td>06.09.2025 - 13.09.2025</td>
								<td>650,00 €</td>
								<td>7</td>
								<td>Any</td>
							</tr>
							<tr>
								<td>13.09.2025 - 20.09.2025</td>
								<td>600,00 €</td>
								<td>7</td>
								<td>Any</td>
							</tr>
                            <tr>
                                <td>20.09.2025 - 27.09.2025</td>
                                <td>500,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
                                <td>27.09.2025 - 04.10.2025</td>
                                <td>400,00 €</td>
                                <td>7</td>
                                <td>Any</td>
                            </tr>
							<tr>
                                <td>04.10.2025 - 11.10.2025</td>
                                <td>400,00 €</td>
                                <td>5</td>
                                <td>Any</td>
                            </tr>
							<tr>
								<td>11.10.2025 - 01.11.2025</td>
								<td>350,00 €</td>
								<td>5</td>
								<td>Any</td>
							</tr>
							<tr>
								<td>01.11.2025 - 13.12.2025</td>
								<td>300,00 €</td>
								<td>5</td>
								<td>Any</td>
							</tr>
                            <tr>
                                <td>13.12.2025 - 07.01.2026</td>
                                <td>800,00 €</td>
                                <td>5</td>
                                <td>Any</td>
                            </tr>
						</table>
                        <p class="text-left">*5% discount for early booking</p>
						<p class="text-left">*heated pool - upon request - 10€ per day</p>
						<p class="text-left">*Jacuzzi - upon request - 10€ per day</p>
						<div class="spacer"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'

	export default {
		name: "calendar",
		data () {
			return {
				querySent: true,
				desktopView: true,

				form: {
					name: '',
					email: '',
					subject: '',
					personNum: '',
					message: ''
				},
				persons: [
					{value: null, text: 'No of Persons*', disable: true},
					{value: 1, text: '1 person'},
					{value: 2, text: '2 person'},
					{value: 3, text: '3 person'},
					{value: 4, text: '4 person'},
					{value: 5, text: '5 person'},
					{value: 6, text: '6 person'},
					{value: 3, text: '7 person'},
					{value: 4, text: '8 person'},
					{value: 5, text: '9 person'},
					{value: 6, text: '10 person'},
				],

				months: [
					{ name: "Jan", fullName: "January", num: 1, days: "31", weeks: [] },
					{ name: "Feb", fullName: "February", num: 2, days: "29", weeks: [] },
					{ name: "Mar", fullName: "March", num: 3, days: "31", weeks: [] },
					{ name: "Apr", fullName: "April", num: 4, days: "30", weeks: [] },
					{ name: "May", fullName: "May", num: 5, days: "31", weeks: [] },
					{ name: "Jun", fullName: "June", num: 6, days: "30", weeks: [] },
					{ name: "Jul", fullName: "July", num: 7, days: "31", weeks: [] },
					{ name: "Aug", fullName: "August", num: 8, days: "31", weeks: [] },
					{ name: "Sep", fullName: "September", num: 9, days: "30", weeks: [] },
					{ name: "Oct", fullName: "October", num: 10, days: "31", weeks: [] },
					{ name: "Nov", fullName: "November", num: 11, days: "30", weeks: [] },
					{ name: "Dec", fullName: "December", num: 12, days: "31", weeks: [] }
				],

				days: [
					{ name: "Mon", num: 0, shortName: "Mo" },
					{ name: "Tue", num: 1, shortName: "Tu" },
					{ name: "Wed", num: 2, shortName: "We" },
					{ name: "Thu", num: 3, shortName: "Th" },
					{ name: "Fri", num: 4, shortName: "Fr" },
					{ name: "Sat", num: 5, shortName: "Sa" },
					{ name: "Sun", num: 6, shortName: "Su" }
				],
                today: {},
				startView: {
					monthOne: {},
					monthTwo: {},
					year: '',
				},
				selectedView: {
					monthOne: {},
					monthTwo: {},
					year: '',
				},
                // Odabrani dani, u kojem su mjesecu i kojoj godini
                selectedDays: [],
                arrivalYear: '',
                arrivalDay: '',
                departureDay: '',
                arrivalMonth: '',
                departureMonth: '',
                departureYear: '',
                tempSave: {},

                dateList: [],
				bookedDays: [],
                loading: true,
			}
		},
		async created() {
            this.loading = true;
			if (window.innerWidth < 600) {
				this.desktopView = false;
			}
            let data = this.$store.getters.getBookingData;
            if (data.selectedDays !== undefined && data.selectedDays.length > 0) {
                this.selectedDays = data.selectedDays;
                this.arrivalYear = this.arrivalYear = data.arrivalYear;
                this.arrivalMonth = this.arrivalMonth = data.arrivalMonth;
                this.arrivalDay = data.arrivalDay;
                this.departureYear = data.departureYear;
                this.departureMonth = data.departureMonth;
                this.departureDay = data.departureDay;
            }
			let t = new Date();
			t = String(t);
			let words = t.split(' ');
			this.setUpTodaysDate(words[0], words[1], words[2], words[3]);
			await this.retrieveCalendarData();
            if (this.dateList.length > 0)   this.loading = false;
            else {
                this.daysInFeb();
                await this.getBookedDates();
                await this.setToday();
            }
		},
		methods: {
		    handleSelectedDates () {
                this.selectedDays = this.tempSave.selectedDays;
                this.arrivalYear = this.tempSave.arrivalYear;
                this.arrivalMonth = this.tempSave.arrivalMonth;
                this.arrivalDay = this.tempSave.arrivalDay;
                this.departureYear = this.tempSave.departureYear;
                this.departureMonth = this.tempSave.departureMonth;
                this.departureDay = this.tempSave.departureDay;
                this.tempSave = {};
            },
            hideSelectedDates () {
                this.tempSave.selectedDays = this.selectedDays;
                this.tempSave.arrivalYear = this.arrivalYear;
                this.tempSave.arrivalMonth = this.arrivalMonth;
                this.tempSave.arrivalDay = this.arrivalDay;
                this.tempSave.departureYear = this.departureYear;
                this.tempSave.departureMonth = this.departureMonth;
                this.tempSave.departureDay = this.departureDay;
                this.selectedDays = [];
                this.arrivalYear = '';
                this.arrivalMonth = '';
                this.arrivalDay = '';
                this.departureYear = '';
                this.departureMonth = '';
                this.departureDay = '';
            },
		    async saveCalendarData () {
                await this.$store.commit('setCalendarData', {year: this.selectedView.year, dateList: this.dateList});
            },
            async retrieveCalendarData () {
                await this.$store.commit('setCurrentYear', this.selectedView.year);
                let i = await this.$store.getters.getCalendarData;
                if (i.length > 0)    this.dateList = i[0].dateList;
                else    this.dateList = i;

            },
            daysInFeb () {
                this.months[1].days = new Date(this.selectedView.year, 2, 0).getDate();
				
            },
			async getBookedDates() {
				const id = '459875';
				const maxRetries = 50; // Maximum retry attempts
				const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms)); // Delay helper
				let success = false;

				for (let attempt = 0; attempt <= maxRetries; attempt++) {
					try {
						const response = await axios.get('https://h3.solutions/api/reservations/' + id);
						const t1 = response.data.data;
						const t2 = t1.filter(item => item.year === this.selectedView.year);
						this.bookedDays = t2[0] || {};
						//console.log("Primary API response: ", response.data.data);
						success = true;
						break; // Exit loop on success
					} catch (error) {
						//console.log(`Attempt ${attempt + 1} failed: `, error);
						if (attempt < maxRetries) {
							//console.log(`Retrying... (${attempt + 1}/${maxRetries})`);
							await delay(1); // Wait before retrying
						}
					}
				}

				if (!success) {
					//console.log('All retries failed. Switching to backup API.');
					await this.backupApiReservations(id); // Call the backup method
				}

				this.setUpCalendar(); // Finalize setup
        	},
			async backupApiReservations (id) {
				await axios.get("https://www.h3.solutions/b-api/api/reservations/" + id)
						.then((response) => {
							this.bookedDays = response.data.data;
						})
						.catch((error) => {
							console.log('Error: ', error);
						})
			},
			setUpTodaysDate: function (day, month, dayNum, year) {
		        this.today = {
		            day: Number(dayNum),
                    month: 0,
                    year: Number(year)
                }
				this.startView.year = this.selectedView.year = Number(year);
				this.months.forEach(function (value, index) {
					if (value.name === month) {
					    if (value.num === 12) {
					        if (this.desktopView) {
                                this.startView.monthOne = this.selectedView.monthOne = this.months[10];
                                this.startView.monthTwo = this.selectedView.monthTwo = value;
                            } else {
                                this.startView.monthTwo = this.selectedView.monthOne = value;
                            }
                        } else {
                            this.startView.monthOne = this.selectedView.monthOne = value;
                            this.startView.monthTwo = this.selectedView.monthTwo = this.months[index + 1];
                        }
					    this.today.month = value.num;
					}
				}, this);
			},
			setUpCalendar: function () {
				let d = null;
				let weeks = [];
				let week = [];
				let startingDay = null;
				let dayOfTheWeek = 0;
				let weekCounter = 1;
				let lastMonthDays = 31;
				let lmSub = null;
				let year = this.selectedView.year;
				this.months.forEach(function(value) {
					startingDay = Number(new Date(year, value.num - 1).getDay()) - 1;
                    if (startingDay === -1) startingDay = 6;
					lmSub = lastMonthDays + 1 - startingDay;
					d = Number(value.days);
					for (let i = 1; i <= d; dayOfTheWeek++) {
						if (dayOfTheWeek === 7) {
							dayOfTheWeek = 0;
						}
						if (dayOfTheWeek < startingDay && weekCounter === 1) {
							week.push({value: lmSub, class: 'gray'});
							lmSub++;
						} else if ( dayOfTheWeek < 6 && i === d) {
							let j = 1;
							if (this.checkDay(i, value.num)) {
								week.push({value: i, class: 'red'});
							} else {
								week.push({value: i, class: 'free'});
							}
							dayOfTheWeek++;
							while (dayOfTheWeek <= 6) {
								week.push({value: j, class: 'gray'});
								dayOfTheWeek++;
								j++;
							}
							weeks.push(week);
							this.storeCalendarDays(value.num, weeks);
							week = [];
							weeks = [];
							i++;
						} else if (i === d) {
							if (this.checkDay(i, value.num)) {
								week.push({value: i, class: 'red'});
							} else {
								week.push({value: i, class: 'free'});
							}
							weeks.push(week);
							this.storeCalendarDays(value.num, weeks);
							week = [];
							weeks = [];
							i++;
						} else {
							if (this.checkDay(i, value.num)) {
								week.push({value: i, class: 'red'});
							} else {
								week.push({value: i, class: 'free'});
							}
							i++;
						}
						if (dayOfTheWeek >= 6) {
							weekCounter++;
							weeks.push(week);
							week = [];
						}
					}
					d = null;
					weeks = [];
					week = [];
					startingDay = null;
					dayOfTheWeek = 0;
					weekCounter = 1;
				}, this);
				this.loading = false;
			},
			checkDay: function (i, m) {
                let status = false;
		        if (!this.isObjectEmpty(this.bookedDays)) {
                    this.bookedDays.month.forEach(function (value) {
                        if (value.m ===  m) {
                            value.d.forEach(function (day) {
                                if (day === i) {
                                    status = true;
                                }
                            })
                        }
                    });
                }
				return status;
			},
			storeCalendarDays: function (month, weeksList) {
				this.dateList.push({month: month,weeks: weeksList});
			},
            async setToday () {
		        if (this.selectedView.year === this.today.year) {
                    let m = await this.dateList.filter(item => item.month === 12);
                    m[0].weeks.forEach(function(item) {
                        let i = item.filter(itemInner => itemInner.value === this.today.day)
                        if (i.length > 0) {
                            i[0].class = i[0].class.concat(' today');
                        }
                    }, this);
                }
            },
			async prevGo () {
                const i = this.selectedView.monthOne.num - 1;
				if (this.selectedView.monthOne.num > this.startView.monthOne.num || this.selectedView.year > this.startView.year) {
                    if (this.selectedView.monthOne.num === 1) {
                        await this.saveCalendarData();
                        this.loading = true;
                        this.selectedView.year--;
                        if (this.selectedView.year === this.tempSave.arrivalYear)   this.handleSelectedDates()
                        this.dateList = [];
                        await this.retrieveCalendarData();
                        if (this.dateList.length > 0) {
                            this.manageMonthViewOnDevices('previous');
                            this.loading = false;
                        } else {
                            this.daysInFeb();
                            await this.getBookedDates();
                            this.manageMonthViewOnDevices('previous');
                        }
                    } else {
                        this.selectedView.monthOne = this.months[i - 1];
                        this.selectedView.monthTwo = this.months[i];
                    }
				}
			},
            manageMonthViewOnDevices (orientation) {
		        if (this.desktopView) {
		            if (orientation === 'next') {
                        this.selectedView.monthOne = this.months[0];
                        this.selectedView.monthTwo = this.months[1];
                    } else {
                        this.selectedView.monthOne = this.months[10];
                        this.selectedView.monthTwo = this.months[11];
                    }
                } else {
		            if (orientation === "next") this.selectedView.monthOne = this.months[0];
		            else    this.selectedView.monthOne = this.months[11];
                }
            },
			async nextGo () {
                let i = this.selectedView.monthOne.num - 1;
                if ((this.desktopView && this.selectedView.monthTwo.num === 12) || this.selectedView.monthOne.num === 12) {
                    await this.saveCalendarData();
                    this.loading = true;
                    let temp =  this.selectedView.year++;
                    if (this.selectedView.year === this.tempSave.arrivalYear)   this.handleSelectedDates();
                    else if (temp-- === this.arrivalYear) {
                        this.hideSelectedDates();
                    }
                    this.dateList = [];
                    await this.retrieveCalendarData();
                    if (this.dateList.length > 0) {
                        this.manageMonthViewOnDevices('next');
                        this.loading = false;
                    } else {
                        this.daysInFeb();
                        await this.getBookedDates();
                        this.manageMonthViewOnDevices('next');
                    }
                } else {
                    this.selectedView.monthOne = this.months[i + 1];
                    this.selectedView.monthTwo = this.months[i + 2];
                }
			},
			sendInquiry: function () {
				const id = '459875';
				const inquiry = {
					to: ['hrvoje@istra-vacation.com'], //'info@villa-montekope.hr'
					senderEmail: this.form.email,
					senderName: this.form.name,
					subject: this.form.subject,
					pN: this.form.personNum,
					message: this.form.message
				};
				console.log(inquiry);
				axios.post("https://www.h3.solutions/api/mailing/" + id, inquiry)
						.then(() => {
							this.successSubmit = true;
						})
						.catch(() => {
							axios.post("https://www.h3.solutions/b-api/api/mailing" + id, inquiry)
									.then(() => {
										this.successSubmit = true;
									})
									.catch((error) => {
										console.log('Error: ', error)
										this.dangerSubmit = true;
									})
						})
						.finally(() => {
							this.resetForm();
						})
			},
			resetForm: function () 	{
                this.eraseSelectedDays('all');
				this.form.email = '';
				this.form.name = '';
				this.form.subject = '';
				this.form.personNum = '';
				this.form.message = '';
			},
            isObjectEmpty (obj) {
                return Object.keys(obj).length === 0 && obj.constructor === Object
            },
            clickEventMidHandler (day, month) {
                let objState = this.isObjectEmpty(this.tempSave);
                if (objState) {
                    console.log('empty: ', objState, day.value, this.today.day, month.num, this.today.month);
                    if (this.selectedView.year > this.today.year) {
                        console.log('here #1');
                        return true;
                    }
                    else if (this.selectedView.year === this.today.year && month.num > this.today.month)    {
                        console.log('here#2', month.num, this.today.month);
                        return true;
                    }
                    else if (this.selectedView.year === this.today.year && month.num === this.today.month && day.value > this.today.day) {
                        console.log('here#3');
                        return true;
                    }
                    else    return false;
                } else {
                    return this.tempSave.arrivalYear === this.selectedView.year;
                }
            },
            clickEvents (day, month) {
		        if (this.clickEventMidHandler(day, month)) {
                    // PRVI CLICK
                    if (this.arrivalDay === '') {
                        this.arrivalDay = day;
                        this.arrivalDay.class = 'selected';
                        this.selectedDays.push(day);
                        this.arrivalMonth = month;
                        this.arrivalYear = this.selectedView.year;
                    } // PONOVNI KLIK NA DAN DOLASKA - BRISE SVE
                    else if (day.value === this.arrivalDay.value && month.num === this.arrivalMonth.num) {
                        this.arrivalDay = '';
                        this.arrivalMonth = '';
                        this.arrivalYear = '';
                        this.eraseSelectedDays('all');
                        this.form.message = '';
                    } // PONOVNI KLIK NA DAN ODLASKA - BRISE DAN ODLASKA I SREDNJE DANE
                    else if (day.value === this.departureDay.value && month.num === this.departureMonth.num) {
                        this.departureDay = '';
                        this.departureMonth = '';
                        this.departureYear = '';
                        this.eraseSelectedDays('skip-first');
                        this.form.message = 'From: '+ this.arrivalDay.value + '.' + this.arrivalMonth.num + '.';
                    } // DODAVANJE DANA ODLASKA
                    else {
                        // AKO SE BORAVAK PROTEXZE KROZ VISE MJESECI ILI AKO JE ODABRAN DAN VECI OD DANA DOLASKA
                        if (month.num > this.arrivalMonth.num || day.value > this.arrivalDay.value || this.currentYear > this.startView.year) {
                            this.departureDay = day;
                            this.departureDay.class = 'selected';
                            this.departureMonth = month;
                            this.departureYear = this.selectedView.year;
                            this.bookingOptions();
                            this.form.message = 'From: '+ this.arrivalDay.value + '.' + this.arrivalMonth.num + '.\nTo: ' + this.departureDay.value + '.' + this.departureMonth.num + '.';
                        }
                    }
                }
            },
            bookingOptions () {
                if (this.arrivalMonth.num === this.departureMonth.num)   {
                    let month = this.dateList[this.arrivalMonth.num - 1];
                    this.selectingDaysThroughLoop(month.weeks, 1);
                }
                else {
                    let months = [];
                    this.dateList.forEach(function (m) {
                        if (m.month === this.arrivalMonth.num || (m.month > this.arrivalMonth.num && m.month <= this.departureMonth.num)) {
                            months.push(m);
                        }
                    }, this);
                    let length = months.length;
                    months.forEach(function (m, index) {
                        if (index === 0)    this.selectingDaysThroughLoop(m.weeks, 2);
                        else if (index === length - 1)  this.selectingDaysThroughLoop(m.weeks, 4);
                        else    this.selectingDaysThroughLoop(m.weeks, 3);
                    }, this);
                }
                this.$store.commit('setBookingData', {selectedDays: this.selectedDays, arrivalYear: this.arrivalYear, arrivalMonth: this.arrivalMonth, departureYear: this.departureYear, departureMonth: this.departureMonth, arrivalDay: this.arrivalDay, departureDay: this.departureDay})
            },
            selectingDaysThroughLoop (weeks, selector) {
                switch (selector) {
                    case 1:
                        weeks.forEach(function(week) {
                            week.forEach(function (day) {
                                if (day.value > this.arrivalDay.value && day.value <= this.departureDay.value && day.class !== 'gray') {
                                    day.class = 'selected';
                                    this.selectedDays.push(day);
                                }
                            }, this);
                        }, this);
                        break;
                    case 2:
                        weeks.forEach(function(week) {
                            week.forEach(function (day) {
                                if (day.value > this.arrivalDay.value && day.class !== 'gray') {
                                    day.class = 'selected';
                                    this.selectedDays.push(day);
                                }
                            }, this);
                        }, this);
                        break;
                    case 3:
                        weeks.forEach(function(week) {
                            week.forEach(function (day) {
                                if (day.class !== 'gray') {
                                    day.class = 'selected';
                                    this.selectedDays.push(day);
                                }
                            }, this);
                        }, this);
                        break;
                    case 4:
                        weeks.forEach(function(week) {
                            week.forEach(function (day) {
                                if (day.value <= this.departureDay.value && day.class !== 'gray') {
                                    day.class = 'selected';
                                    this.selectedDays.push(day);
                                }
                            }, this);
                        }, this);
                        break;
                }
            },
            eraseSelectedDays (selector) {
                let first = this.selectedDays[0];
                this.selectedDays.forEach(function(day) {
                    day.class = 'free';
                })
                this.selectedDays = [];
                if (selector === 'skip-first') {
                    this.selectedDays.push(first);
                    this.selectedDays[0].class = 'selected';
                }
            },
		},
        beforeDestroy() {
            this.saveCalendarData();
        }
	}
</script>

<style>
.text-left{
	margin-bottom:0.6rem;
}
	.cal-btn {
		border: none;
		background: transparent;
		padding: 0;
		margin: 0;
	}
	.cal-btn:hover {
		background-color: #3f85ce;
		color: #ffffff;
	}
	.price-list {
		width: 100%;
	}
	.price-list tr {
		height: 50px;
	}
	.price-list tr:nth-child(odd) {
		background-color: #3f85ce;
		color: #ffffff;
	}
	.price-list tr td, .price-list tr th {
		width: 50px;
	}
	.calendar {
		color: #000;
		width: 50%;
		border-collapse: collapse;
	}
    .today {
        border: 1px solid #3f85ce;
    }
	.free {
		color: black;
		cursor: pointer;
		-webkit-transition: all 500ms;
		-moz-transition: all 500ms;
		-ms-transition: all 500ms;
		-o-transition: all 500ms;
		transition: all 500ms;
	}
	.free:hover, .selected {
		background-color: #3f85ce;
		color: #ffffff;
	}
	.free:active {
		background-color: rebeccapurple;
		color: #ffffff;
	}
	.gray {
		color: #cecece !important;
	}
	.red {
		color: #ffffff;
		background-color: #ff1919;
	}

	input[type=text], textarea, select {
		border: 1px solid #ececec;
		box-shadow: none;
		width: 100%;
		height: 50px;
		padding: 0 23px;
		outline: none;
		color: #303030;
		margin-bottom: 20px;
		background: 0 0;
	}
	.mgs-response {
		position: relative;
		overflow: hidden;
		height: 1px;
		width: 100%;
		border: 0;
		margin: 0;
		padding: 0;
	}
	form {
		text-align: left;
	}
	input, select {
		vertical-align: middle;
		outline: none !important;
	}
	input {
		font-family: inherit;
		font-size: inherit;
		line-height: inherit;
		margin: 0;
	}
	textarea {
		height: 97px;
		resize: none;
	}
	label {
		width: 100%;
	}
	.padding-r-40 {
		padding-right: 40px;
	}

	/***************/
	/* RESPONSIVE */
	@media (max-width: 600px) {
		.calendar {
			width: 100%;
		}
		.padding-l-40 {
			padding-left: 40px;
		}
		.padding-r-40 {
			padding-right: 0;
		}
	}
	@media (max-width: 575px) {
		.head-sub {
			text-align: center;
		}
		p {
			text-align: center;
		}
	}

</style>
